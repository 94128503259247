import { styled } from 'styled-components';
import { device, size } from '../../utils/variables';

export default styled.div`
    background-color: var(--bg-gray);
    width: 100%;

    @media ${device.lg} {
        width: auto;
        padding: 0;
        max-width: ${size.xl};
        margin: 2rem auto;
    }
`;
