import { FacebookLoginData, GoogleLoginData, SocialLoginType } from '../types/social';
import axios from '../utils/axios';
import i18n from '../utils/i18n';
import { getTokens, setToken } from '../utils/token';

export const authWithSocial = async (data: FacebookLoginData | GoogleLoginData, type: SocialLoginType) => {
    return axios.post('/auth/login-social', { ...data, type, locale: i18n.language });
};

export const authWithEmailPassword = async (email: string, password: string) => {
    return axios.post<{ locale: string; accessToken: string; refreshToken: string }>('/auth/login', { email, password });
};

export const register = async (email: string, password: string, firstName: string, lastName: string, locale: string) => {
    return axios.post('/auth/register', { email, password, firstName, lastName, locale });
};

export const refreshToken = async () => {
    try {
        const tokens = getTokens();
        const result = await axios.post('/auth/refresh', tokens);
        setToken(result.data);
    } catch (err) {
        console.log(err);
    }
};

export const getIsEmailAvailable = async (email: string) => {
    try {
        const result = await axios.get(`/auth/available/${email}`);
        return Boolean(result.data);
    } catch (err) {
        console.log(err);
    }
};
