import { InputProps, InputRef } from 'antd';
import React from 'react';
import InputWrapper from './styles';

type Props = InputProps & {
    children?: React.ReactNode;
    inputRef?: React.RefObject<InputRef>;
};

export function UiInput({ children, inputRef: propRef, ...props }: Props) {
    return (
        <InputWrapper ref={propRef} {...props}>
            {children}
        </InputWrapper>
    );
}
