import axios from '../utils/axios';

export const reportMissingTranslation = async (prefix: string, text: string, namespace: string, language: string) => {
    if (!text) return;

    try {
        axios.post('/translations/missing', { prefix, text, namespace, language });
    } catch (err) {
        console.log(err);
    }
};
