import React from 'react';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/footer/Footer';
import Wrapper from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface Props {
    children?: JSX.Element;
}

export function LayoutBasic({ children }: Props) {
    const isFooterHidden = useSelector((state: RootState) => state.footer.isFooterHidden);

    return (
        <Wrapper>
            <Header />
            {children}
            {!isFooterHidden && <Footer />}
        </Wrapper>
    );
}
