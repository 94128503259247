import { createElement } from 'react';
import { useTranslation as useI18NexTranslation } from 'react-i18next';
import { reportMissingTranslation } from '../api/translations';
import i18n from '../utils/i18n';

export function useTranslation() {
    const { t } = useI18NexTranslation();
    const translateHtml = (text: string, prefix: string, namespace = 'translation') => {
        const translationKey = `${prefix}.${text}`;
        let translation = '';
        if (!i18n.exists(translationKey, { ns: namespace, lng: i18n.language })) {
            reportMissingTranslation(prefix, text, namespace, i18n.language);
            translation = text;
        } else {
            translation = t(translationKey, { ns: namespace, lng: i18n.language });
        }
        const props = {
            dangerouslySetInnerHTML: {
                __html: translation,
            },
        };
        const element = createElement('span', props);
        return element;
    };

    const translate = (text: string, prefix: string, namespace = 'translation') => {
        const translationKey = `${prefix}.${text}`;
        let translation = '';
        if (!i18n.exists(translationKey, { ns: namespace, lng: i18n.language })) {
            reportMissingTranslation(prefix, text, namespace, i18n.language);
            translation = text;
        } else {
            translation = t(translationKey, { ns: namespace, lng: i18n.language });
        }
        return translation;
    };

    return { translate, translateHtml };
}
