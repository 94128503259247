import styled from 'styled-components';
import { size } from '../../utils/variables';

export default styled.header`
    height: 88px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e8e8ea;

    .header {
        max-width: ${size.xl};
        width: 100%;
        display: grid;
        grid-template-columns: 10% 50% 40%;
        align-items: center;
    }

    .ant-menu {
        background: transparent;
        border: 0;
        font-size: 16px;
        font-family: Golos;
        align-items: center;

        .ant-menu-item::after {
            border-bottom: none;
        }
    }

    .ant-menu-item {
        display: flex;
        padding-right: 0;

        &.join {
            color: #fff;
            padding: 4px 18px 4px 18px;
            border-radius: 4px;

            img {
                max-height: 18px;
            }

            .ant-btn-primary {
                display: flex;
                align-items: center;
                font-size: 16px;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .signup-login {
            margin-left: 40px;

            .login {
                padding: 18px 24px;
                border: 1px solid #e8e8ea;
                border-radius: 6px;
                margin-left: 20px;
                height: auto;
            }
        }

        .account {
            margin-left: 16px;
        }
    }

    .ant-dropdown-trigger {
        display: flex;
        align-items: center;

        img {
            margin-right: 8px;
        }
    }
`;
