import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import Container from '../../../components/container/Container';
import { getHeightToFitInWindowWithElementOffset, getRoute } from '../../../utils/browser';
import { Alert, Card, Form, Input, Typography } from 'antd';
import { UiButton } from '../../../ui/button/UiButton';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from '../../../hooks/use-translation';
import axios from '../../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';

export function ChangePasswordPage() {
    const [pageHeight, setPageHeight] = useState(0);
    const [form] = useForm();
    const { translate } = useTranslation();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState<'error' | 'success'>('error');

    useEffect(() => {
        const heightToFitWindow = getHeightToFitInWindowWithElementOffset('.change-password-container', 'footer');
        setPageHeight(heightToFitWindow);
    }, []);

    async function changePassword() {
        try {
            form.validateFields();
            const values = form.getFieldsValue();
            await axios.post('/account/change-password', values);
            setMessageType('success');
            setMessage('Password changed');
            form.resetFields();
        } catch (err) {
            if (err instanceof AxiosError) {
                setMessage(err?.response?.data?.description);
            }
        }
    }

    return (
        <Wrapper height={pageHeight} className="change-password-container">
            <Container className="container">
                <Card
                    title={
                        <UiButton type="link" onClick={() => navigate(getRoute('settings/profile'))}>
                            <ArrowLeftOutlined className="back-arrow" />
                            Back
                        </UiButton>
                    }
                >
                    <Typography.Title level={2}>Change password</Typography.Title>
                    {message && <Alert message={message} type={messageType} />}
                    <Form form={form}>
                        <Form.Item
                            name="currentPassword"
                            rules={[{ required: true, message: translate('This field is required', 'ui.change-password') }]}
                        >
                            <Input placeholder="Current password" type="password" size="large" />
                        </Form.Item>
                        <Form.Item
                            name="newPassword"
                            rules={[{ required: true, message: translate('This field is required', 'ui.change-password') }]}
                        >
                            <Input placeholder="New password" type="password" size="large" />
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassword"
                            dependencies={['newPassword']}
                            rules={[
                                { required: true, message: translate('This field is required', 'ui.change-password') },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(translate('The new password that you entered do not match!', 'ui.change-password')),
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Confirm new password" type="password" size="large" />
                        </Form.Item>
                        <Form.Item>
                            <UiButton htmlType="submit" type="primary" size="large" onClick={changePassword}>
                                {translate('Save', 'ui.change-password')}
                            </UiButton>
                        </Form.Item>
                    </Form>
                </Card>
            </Container>
        </Wrapper>
    );
}
