import { styled } from 'styled-components';
import { device, size } from '../../utils/variables';

export default styled.footer`
    width: 100%;
    padding-top: 5em;
    background-color: #fff;

    .footer-content {
        width: 100%;
        max-width: ${size.xl};
        margin: 0 auto;
    }

    @media ${device.lg} {
        .footer-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    .quick-links {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0 10px;

        @media ${device.lg} {
            flex-direction: row;
            width: auto;
        }

        a {
            position: relative;
            @media ${device.lg} {
                text-align: center;
            }
            &:first-of-type {
                margin-bottom: 15px;
                @media ${device.lg} {
                    margin-bottom: 0;
                    margin-right: 25px;
                }
            }
            &:not(:first-of-type) {
                width: 100%;
                border-bottom: 1px solid rgba(50, 19, 52, 0.2);
                padding-bottom: 15px;

                @media ${device.lg} {
                    border-bottom: 0;
                    width: auto;
                    margin-right: 25px;
                }

                &:after {
                    content: '->';
                    position: absolute;
                    right: 15px;
                    transform: rotate(-25deg);

                    @media ${device.lg} {
                        content: '';
                    }
                }
            }
        }
    }

    .social-links {
        padding: 0 10px;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;

        @media ${device.lg} {
            margin-top: 0;
            margin-bottom: 50px;
        }

        a {
            margin-left: 25px;
        }
    }

    .ant-divider {
        margin-bottom: 0;
    }

    .copyright {
        max-width: ${size.xl};
        height: 100px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        margin: 0 auto;

        @media ${device.lg} {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .ant-typography,
        a {
            color: #9b9b9b;
        }
    }
`;
