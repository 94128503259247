import React from 'react';
import { getCloudinaryImageUrl } from '../../utils/image';

interface Props {
    src: string;
    alt?: string;
    className?: string;
}

export function UiImage({ src, alt, className }: Props) {
    const imageSrc = getCloudinaryImageUrl(src);
    return <img src={imageSrc} alt={alt ?? ''} className={className || ''} />;
}
