import React, { useEffect, useState } from 'react';
import { UiImage } from '../../../../ui/image/UiImage';
import { Form, Input, Typography, notification } from 'antd';
import { useTranslation } from '../../../../hooks/use-translation';
import { UiButton } from '../../../../ui/button/UiButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { requestResetUpdate } from '../../../../api/password';
import { getRoute } from '../../../../utils/browser';

export function ResetPasswordForm() {
    const { translate } = useTranslation();
    const { search } = useLocation();
    const [token, setToken] = useState('');
    const [form] = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (!search) return;
        const tokenFromQuery = new URLSearchParams(search).get('token');
        setToken(tokenFromQuery ?? '');
    }, [search]);

    async function submitResetPasswordRequest() {
        const formValues = form.getFieldsValue();
        const isSuccess = await requestResetUpdate({ token, ...formValues });
        if (isSuccess) {
            navigate(getRoute('account/reset-password/success'));
        } else {
            notification.error({ message: 'Something went wrong!!' });
        }
    }

    return (
        <>
            <UiImage src="assets/reset-password" />
            <Typography.Title level={2}>{translate('Password recovery', 'reset-password')}</Typography.Title>
            <Form form={form}>
                <Form.Item name="newPassword">
                    <Input type="password" placeholder={translate('New password', 'reset-password')} size="large" />
                </Form.Item>
                <Form.Item name="newPasswordConfirm">
                    <Input type="password" placeholder={translate('Repeat new password', 'reset-password')} size="large" />
                </Form.Item>
                <Form.Item>
                    <UiButton type="secondary" size="large" onClick={submitResetPasswordRequest}>
                        {translate('Change password', 'reset-password')}
                    </UiButton>
                </Form.Item>
            </Form>
        </>
    );
}
