import axios from '../utils/axios';

export const getUserWithdrawRequests = async () => {
    try {
        const response = await axios.get<any>(`/withdraw/requests`);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const createWithdrawRequest = async (amount: number) => {
    try {
        const response = await axios.post<any>(`/withdraw/create-request`, { amount }); // TODO: proper types
        return response.data;
    } catch (err) {
        console.log(err);
    }
};
