import { styled } from 'styled-components';
import { size } from '../../../utils/variables';

export default styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
    max-width: ${size.xl};
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 75px;

    .ant-card {
        max-width: 570px;
        width: 100%;
        padding: 44px 34px;
        box-shadow: 0px 9px 54px 0px rgba(215, 218, 222, 0.2);

        h2 {
            margin-top: 0;
            text-align: center;
        }
    }

    .social-links {
        display: flex;
        flex-direction: column;
        justify-content: center;

        div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 0 auto;
            min-height: 56px;
            border: 1px solid #321335;
            border-radius: 6px;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }

            img {
                width: 30px;
                height: 30px;
                margin-right: 16px;
            }
        }
    }

    input {
        padding: 15px;
    }

    .login-btn {
        margin-top: 1rem;
        width: 100%;
    }
`;
