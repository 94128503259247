import styled from 'styled-components';

export default styled.div`
    .success {
        filter: hue-rotate(100deg);
    }

    & > span {
        font-size: 1.2rem;
        text-align: center;
        width: 100%;
        display: inline-block;
        margin-bottom: 1.5rem;
    }
`;
