import { Divider, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import Wrapper from './styles';
import { getRoute } from '../../utils/browser';
import { UiImage } from '../../ui/image/UiImage';
import { useTranslation } from '../../hooks/use-translation';
import { getRedirectLink } from '../header/menu/helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export function Footer() {
    const { Text } = Typography;
    const { translate } = useTranslation();
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    return (
        <Wrapper>
            <div className="footer-content">
                <div className="quick-links">
                    <Link to={getRedirectLink(isAuthenticated)}>
                        <UiImage src="assets/logo" />
                    </Link>
                    <Link to="/">{translate('Home', 'footer')}</Link>
                    <Link to={getRoute('#how-it-works')}>{translate('How it works', 'footer')}</Link>
                    <Link to={getRoute('#tips')}>{translate('Tips', 'footer')}</Link>
                    <Link to={getRoute('contacts')}>{translate('Contacts', 'footer')}</Link>
                </div>
                <div className="social-links">
                    <Link to="/">Instagram</Link>
                    <Link to="/">Facebook</Link>
                    <Link to="/">Twitter</Link>
                </div>
            </div>
            <Divider />
            <div className="copyright">
                <Text>© Digitaltip OÜ. 2022 All Right Reserved.</Text>
                <Link to={getRoute('privacy')}>{translate('Privacy Policy', 'footer')}</Link>
            </div>
        </Wrapper>
    );
}
