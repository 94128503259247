import axios from '../utils/axios';

interface RequestResetUpdate {
    token: string;
    newPassword: string;
    newPasswordConfirm: string;
}
export const requestResetUpdate = async (payload: RequestResetUpdate) => {
    try {
        await axios.post<void>('/password/request-reset/update', payload);
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
};
