import { UserBasicProfile } from '../types/account';
import axios from '../utils/axios';

export const getAccountProfile = async () => {
    try {
        const result = await axios.get<UserBasicProfile>('/account/profile');
        return result.data;
    } catch (err) {
        console.log(err);
    }
};
