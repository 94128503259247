import styled from 'styled-components';
import { device } from '../../../utils/variables';

export default styled.div<{ height: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;

    @media ${device.md} {
        height: ${(props) => props.height}px;
    }

    .ant-alert {
        margin: 1rem 0;
    }

    .ant-card {
        &-body {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 3rem;
            @media ${device.md} {
                min-width: 500px;
            }

            button {
                width: 100%;
            }
        }

        .ant-form-item {
            margin-bottom: 28px;
        }
    }

    .actions {
        display: flex;
        gap: 10px;
        text-transform: uppercase;
    }

    .back-arrow {
        cursor: pointer;
    }
`;
