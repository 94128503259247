/* eslint-disable max-len */
export const eePrivacy = {
    common: {
        privacy: `
    <h1>
        DIGITIP PRIVAATSUSPOLIITIKA
    </h1>
    <div>
        Viimati uuendatud: 11. märtsil, 2021
    </div>
    <div>
        Käesolev privaatsuspoliitika selgitab, kuidas DIGITIP ja selle tütar- ja
        sidusettevõtted (edaspidi: "DIGITIP", "meie") teie teavet koguvad, kasutavad
        ja avaldavad. Käesolev privaatsuspoliitika kehtib teie poolt DIGITIP teenuse
        (edaspidi: "rakendus või teenused") kasutamisel kogutavale teabele või muul
        viisil meiega suhtlemisel.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Muudatused
    </div>
    <div>
        Meil on õigus käesolevat privaatsuspoliitikat aeg-ajalt muuta. Kui muudatusi
        teeme, teavitame teid poliitika ülaosas oleva kuupäeva muutmisega või
        edastame teile täiendava teate (nt teatise kuvamine Rakendusse
        sisselogimisel või meiliteatise saatmine). Soovitame teil
        privaatsuspoliitika üle vaadata iga kord kui teenuseid kasutate, et meie
        teabetavade ja oma privaatsuse kaitsmise võimalustega kursis olla.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Isiklik teave
    </div>
    <div>
        Käesolevas poliitikas kasutatud mõiste "isiklik teave" tähendab teavet, mis
        isiku (nt nimi, kasutajanimi või meiliaadress) tuvastab või teavet selle
        isiku kohta, mida saab seostada teabega, mis isiku tuvastab. Isikuandmed ei
        hõlma koondteavet, milleks on andmed, mida Teenuste kasutamise kohta või
        teenuste või kasutajate rühma või kategooria kohta kogume, millest on
        üksikisikuid identifitseeriv teave või muud isikuandmed eemaldatud. Käesolev
        poliitika ei piira meie koondteabe kogumist ega kasutamist.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Teabe kogumine
    </div>
    <div>
        Teave, mida meile esitate. Kogume teavet, mille meile otse edastate. Näiteks
        kogume teavet, kui loote meie veebilehel konto, osalete Teenuste mistahes
        interaktiivsetes funktsioonides, uuringutes või suhtlete meiega muul viisil.
        Meie poolt kogutava teabe liigid hõlmavad teie nime, meiliaadressi ja muud
        teavet, mille otsustate esitada.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Teie poolsel Teenuste kasutamisel automaatselt kogutav teave. Kogume teie
        kohta automaatset teavet meie Teenustele ligipääsemisel, nende kasutamisel
        või meie veebipõhise rakenduse kaudu, sealhulgas:
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Logiteave: registreerime teavet teie Teenuste kasutamise kohta,
        sealhulgas: teie kasutatava operatsioonisüsteemi tüübi; juurdepääsuaegade;
        teie Partnerite juures osalemise tegevuse; geograafilise asukoha kohta, kui
        olete meile loa andnud, teie läheduses osalevate Partnerite leidmiseks;
        Teenustele juurdepääsu viisi kohta, kas Rakenduse portaali või teie lingitud
        sotsiaalmeedia kontode kaudu.
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Seadmeteave: kogume teavet mobiilseadme kohta, mida meie teenustele
        juurdepääsuks kasutate, sealhulgas riistvaramudel, operatsioonisüsteem ja
        versioon, unikaalsed seadme identifikaatorid ja mobiilsidevõrgu teave.
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Küpsiste ja muude jälgimistehnoloogiate abil kogutav teave: kasutame
        teabe kogumiseks erinevaid tehnoloogiaid, mis võib hõlmata küpsiste saatmist
        teie mobiilseadmesse. Küpsised on väikesed andmefailid, mis on salvestatud
        seadme mällu, mis aitavad meil Teenuseid ja teie kasutuskogemust täiustada,
        jälgida, millised meie Teenuste valdkonnad ja funktsioonid on populaarsed
        ning lugeda külastusi. Kui soovite küpsiseid ja muid jälgimismeetodeid
        blokeerida, kustutada või soovite nende kohta hoiatussõnumeid, saate
        nimetatud funktsioonide lisateavet oma mobiilseadme privaatsusseadetest. Kui
        aga otsustate küpsised ja jälgimise eemaldada või tagasi lükata, võib see
        Teenuste kaudu kättesaadavaks tehtud funktsioone või teenuseid mõjutada.
        Austame teie privaatsusvalikuid olenemata sellest, mida otsustate teha.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Küpsiste ja muude jälgimismeetodite kasutamine meie Partnerite poolt,
        näiteks meie Teenustele juurdepääsuks kasutatav sotsiaalmeedia, ei ole
        hõlmatud meie privaatsuspoliitikaga, vaid nende teenuste
        privaatsuspoliitikaga. Meil ei ole juurdepääsu ega kontrolli kolmandate
        osapoolte privaatsusega seotud tavade üle. Kontrollige kindlasti kolmandate
        osapoolte privaatsuspoliitikaid, et teada saada, kuidas nad teie
        isikuandmeid käsitlevad.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Teabe kasutamine
    </div>
    <div>
        Me ei müü teie teavet. Meil on õigus teie kohta käivat teavet erinevatel
        eesmärkidel kasutada, sealhulgas:
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Meie Teenuste pakkumiseks, hooldamiseks ja täiustamiseks;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Tehniliste teadete, värskenduste, turvahoiatuste ning tugi- ja
        haldussõnumite saatmiseks;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Teie kommentaaridele, küsimustele ja taotlustele vastamiseks;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Teile meie ja teiste pakutavatest teenustest, pakkumistest,
        kampaaniatest, preemiatest ja sündmustest teavitamiseks ning uudiste ja
        teabe edastamiseks, mis meie arvates teile huvi pakuvad;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Meie Teenustega seotud trendide, kasutuse ja tegevuste jälgimiseks ja
        analüüsiks;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Teenuste isikupärastamiseks ja täiustamiseks ning
        kasutajaprofiilidele või huvidele vastavate reklaampakkumiste, sisu või
        funktsioonide esitamiseks;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Teistelt saadud teabe linkimiseks või ühendamiseks, et aidata teie
        vajadusi mõista ja paremat teenust pakkuda;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Mistahes muu eesmärgi täitmiseks, milleks teavet koguti, pärast seda,
        kui oleme teid sellisest eesmärgist teavitanud.
    </div>
    <div>
        <br/>
    </div>
    <div>
    </div>
    <div>
        <br/>
    </div>
    <div>
        DIGITIP asub Eestis ja tegutseb hetkel ainult Eesti piires. Kogutavale
        teabele kohaldatakse Eesti seadusi.
    </div>
    <div>
        <br/>
    </div>
    <div>
    </div>
    <div>
        <br/>
    </div>
    <div>
        Teabe jagamine
    </div>
    <div>
        Meil on õigus teie teavet järgmiselt või käesolevas privaatsuspoliitikas
        kirjeldatud viisil jagada:
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   Partnerite, konsultantide ja teiste teenusepakkujatega, kes meie nimel
        töö teostamiseks sellisele teabele juurdepääsu vajavad;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   Teie nõusolekul või teie korraldusel, sealhulgas juhul, kui teid oma
        Teenuste kaudu teavitame, et teie esitatud teavet teatud viisil jagatakse ja
        teie sellist teavet esitate;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   Vastuseks teabepäringule, kui oleme veendunud, et avaldamine on mistahes
        kohaldatava seaduse, määruse või õigusliku menetlusega kooskõlas või kui
        seda mistahes kohaldatavad seadused, eeskirjad või määrused kohustavad;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   DIGITIPi, selle kasutajate või teiste õiguste, vara ja turvalisuse
        kaitsmiseks, kui meie Tingimusi või kasutajaeeskirju rikute;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   Seoses ühinemise, ettevõtte varade müügi, finantseerimise või kogu meie
        äritegevuse või selle osa omandamisega teise ettevõtte poolt või seoses
        nimetatud läbirääkimistega; ja
    </div>
    <div>
        <br/>
    </div>
    <div>
        Meil on õigus jagada ka koondatud või identifitseerimata teavet, mida ei ole
        teie tuvastamiseks võimalik mõistlikult kasutada.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Pange tähele, et meil puudub kontroll kolmandate osapoolte tegevuse üle,
        kelle teenustele teid meie veebilehe kaudu ümber suunatakse, ja me ei
        vastuta teabe eest, mida otse teistele kasutajatele või kolmandatele
        osapooltele edastate. Enne kolmandatele osapooltele otsese teabe avaldamist
        soovitame teil kolmandate osapoolte privaatsustavadega tutvuda.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Sotsiaalse jagamise funktsioonid
    </div>
    <div>
        Teenused võivad pakkuda sotsiaalse jagamise funktsioone ja muid
        integreeritud tööriistu (nagu Facebooki nupp "Meeldib"), mis võimaldavad
        teil meie Teenustega seotud toiminguid muu meediaga jagada ja vastupidi.
        Selliste funktsioonide kasutamine võimaldab jagada teavet teie sõprade või
        üldsusega, olenevalt sotsiaaljagamisfunktsiooni pakkuva üksuse seadetest.
        Lisateavet sotsiaalse jagamise funktsioonidega seotud andmete kogumise ja
        töötlemise eesmärgi ja ulatuse kohta leiate neid funktsioone pakkuvate
        üksuste privaatsuspoliitikatest.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Turvalisus. DIGITIP on teie privaatsusele ja teie teabe turvalisusele
        pühendunud. Võtame kasutusele meetmed, et aidata teie teavet kadumise,
        väärkasutuse ja volitamata juurdepääsu eest kaitsta.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Teie võimalused
    </div>
    <div>
        <br/>
    </div>
    <div>
        Konto teave. Teil on õigus enda kohta käivat teavet igal ajal uuendada,
        parandada või kustutada, logides sisse oma liikmekontole või saates oma
        soovi meilile info@DIGITIP.ee. Kui soovite oma konto deaktiveerida, on teil
        õigus seda teha igal ajal, kasutades oma liikmekontol olevaid tööriistu või
        võttes meiega ülaltoodud meiliaadressil ühendust, kuid meil on õigus teatud
        teavet vastavalt seadusele säilitada.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Küpsised. Enamik mobiilseadmeid pakuvad oma privaatsusseadetes kolmanda
        osapoole jälgimise desaktiveerimiseks valikuid. Need privaatsusseaded on
        tavaliselt seatud vaikimisi küpsiseid ja muid jälgimistehnoloogiaid
        aktsepteerima. Kui soovite, saate oma privaatsusseadetes jälgimise eemaldada
        või tagasi lükata. Pange tähele, et kui otsustate küpsised ja muud
        jälgimistehnoloogiad eemaldada või tagasi lükata, võib see meie teenuste
        kättesaadavust ja funktsionaalsust mõjutada.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Reklaamsuhtlus. Teil on õigus meie reklaammeilide saamisest loobuda,
        järgides meilides olevaid tellimusest loobumise juhiseid. Kui loobute, on
        meil õigus teile siiski teateid (mitte reklaami) saata, näiteks teie konto
        või meie jätkuva teenindussuhte kohta.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Võtke meiega ühendust
    </div>
    <div>
        Kui teil tekib käesoleva Privaatsuspoliitika kohta küsimusi, võtke meiega
        ühendust aadressil info@DIGITIP.ee
    </div>`,
    },
};

export const enPrivacy = {
    common: {
        privacy: `
    <h1>
        DIGITIP PRIVACY POLICY
    </h1>
    <div>
        Last updated: March 11, 2021
    </div>
    <div>
        This Privacy Policy explains how information about you is collected, used
        and disclosed by DIGITIP and its subsidiaries and affiliated companies
        ("DIGITIP,"  "we," "us," or "our"). This Privacy Policy applies to
        information we collect when you use the DIGITIP service ("the Application or
        the "Services") or when you otherwise interact with us.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Modifications
    </div>
    <div>
        We may change this Privacy Policy from time to time. If we make changes, we
        will notify you by revising the date at the top of the policy and, in some
        cases, we may provide you with additional notice (such as showing a notice
        upon logging into the Application or sending you an email notification). We
        encourage you to review the Privacy Policy whenever you access the Services
        to stay informed about our information practices and the ways you can help
        protect your privacy.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Personal Information
    </div>
    <div>
        As used in this Policy, the term "personal information" means information
        that specifically identifies an individual (such as a name, user name, or
        e-mail address), or information about that individual that can be linked to
        information that specifically identifies an individual. Personal information
        does not include "aggregate" information, which is data we collect about the
        use of the Services or about a group or category of services or users, from
        which individual identities or other personal information has been removed.
        This Policy does not restrict our collection and use of aggregate
        information.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Collection of Information
    </div>
    <div>
        Information You Provide Us. We collect information you provide directly to
        us. For example, we collect information when you create an account upon our
        website, participate in any interactive features of the Services,
        participate in surveys, or otherwise communicate with us. The types of
        information we may collect include your name, email address, and any other
        information you choose to provide.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Information We Collect Automatically When You Use the Services. When you
        access or use our Services, whether via our web-based application, we
        automatically collect information about you, including:
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Log Information:  We log information about your use of the Services,
        including: the type of operating system you use; access times; your activity
        at participating Partners; geo-location, if you have given us permission,
        for purposes of locating participating Partners near you; manner of
        accessing the Services, whether via the Application portal or through your
        linked social media accounts.
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Device Information:  We collect information about the mobile device
        you use to access our Services, including the hardware model, operating
        system and version, unique device identifiers and mobile network
        information.
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Information Collected by Cookies and Other Tracking Technologies: We
        use various technologies to collect information, and this may include
        sending cookies to your mobile device. Cookies are small data files stored
        in device memory that help us to improve our Services and your experience,
        see which areas and features of our Services are popular and count visits.
        If you wish to block, erase, or be warned of cookies and other tracking
        methods, please refer to your mobile device privacy settings to learn about
        these functions. However, if you choose to remove or reject cookies and
        tracking, this could affect certain features or services made available via
        the Services. Regardless of what you choose to do, we honor your privacy
        choices.
    </div>
    <div>
        <br/>
    </div>
    <div>
        The use of cookies and other tracking methods by our partners, such as
        social media which you use to access our Services, is not covered by our
        privacy statement but by that of those services. We do not have access or
        control over third party privacy-related practices. Please make sure that
        you check those third parties' privacy policies to learn how they treat your
        personal information.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Use of Information
    </div>
    <div>
        We do not sell your information. We may use information about you for
        various purposes, including to:
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Provide, maintain and improve our Service;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Send you technical notices, updates, security alerts and support and
        administrative messages;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Respond to your comments, questions and requests;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Communicate with you about services, offers, promotions, rewards, and
        events offered by us and others, and provide news and information we think
        will be of interest to you;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Monitor and analyze trends, usage and activities in connection with
        our Services;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Personalize and improve the Services and provide advertisements,
        content or features that match user profiles or interests;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Link or combine with information we get from others to help
        understand your needs and provide you with better service;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Carry out any other purpose for which the information was collected
        after we have given you notice about such purpose.
    </div>
    <div>
        <br/>
    </div>
    <div>
    </div>
    <div>
        <br/>
    </div>
    <div>
        DIGITIP is based in Estonia and currently operates only within the Estonia.
        The information we collect is governed by Estonian law.
    </div>
    <div>
        <br/>
    </div>
    <div>
    </div>
    <div>
        <br/>
    </div>
    <div>
        Sharing of Information
    </div>
    <div>
        We may share information about you as follows or as otherwise described in
        this Privacy Policy:
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   With Partners, consultants and other service providers who need access
        to such information to carry out work on our behalf;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   With your consent or at your direction, including if we notify you
        through our Services that the information you provide will be shared in a
        particular manner and you provide such information;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   In response to a request for information if we believe disclosure is in
        accordance with any applicable law, regulation or legal process, or as
        otherwise required by any applicable law, rule or regulation;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   If you violate our Terms or user policies, to protect the rights,
        property and safety of DIGITIP, its users or others;
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·   In connection with, or during negotiations of, any merger, sale of
        company assets, financing or acquisition of all or a portion of our business
        to another company; and
    </div>
    <div>
        <br/>
    </div>
    <div>
        We may also share aggregated or de-identified information, which cannot
        reasonably be used to identify you.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Please note that we do not have control over the actions of third-parties to
        whose services you may be redirected via our website and are not responsible
        for information you provide directly to any other users or third- parties.
        We encourage you to become familiar with third-party privacy practices
        before disclosing information directly to any such third-parties.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Social Sharing Features
    </div>
    <div>
        The Services may offer social sharing features and other integrated tools
        (such as the Facebook "Like" button), which let you share actions you take
        on our Services with other media, and vice versa. The use of such features
        enables the sharing of information with your friends or the public,
        depending on the settings you establish with the entity that provides the
        social sharing feature. For more information about the purpose and scope of
        data collection and processing in connection with social sharing features,
        please visit the privacy policies of the entities that provide these
        features.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Security.  DIGITIP is committed to your privacy and the security of your
        information. We take measures to help protect your information from loss,
        misuse, and unauthorized access.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Your Choices
    </div>
    <div>
        <br/>
    </div>
    <div>
        Account Information. You may update, correct or delete information about you
        at any time by logging into your member account or emailing us with your
        request at info@DIGITIP.ee. If you wish to deactivate your account, you may
        do so at any time by using the tools in your member account or by contacting
        us at the email address above, but note that we may retain certain
        information as required or authorized by law.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Cookies. Most mobile devices provide options in their privacy settings to
        deactivate third-party tracking. These privacy settings are usually set to
        accept cookies and other tracking technologies by default. If you prefer,
        you can usually choose to set your privacy settings to remove or reject
        tracking. Please note that if you choose to remove or reject cookies and
        other tracking technologies, this could affect the availability and
        functionality of our Services.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Promotional Communications. You may opt out of receiving promotional emails
        from us by following the unsubscribe instructions in those emails. If you
        opt out, we may still send you non-promotional communications, such as those
        about your account or our ongoing service relationship.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Contact Us
    </div>
    <div>
        If you have any questions about this Privacy Policy, please contact us at
        info@DIGITIP.ee
    </div>`,
    },
};

export const ruPrivacy = {
    common: {
        privacy: `
    <h1>
        ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ КОМПАНИИ DIGITIP
    </h1>
    <div>
        Последнее обновление: 11 марта 2021 г.
    </div>
    <div>
        Настоящая Политика конфиденциальности объясняет, каким образом компания
        DIGITIP и ее дочерние и аффилированные компании ("DIGITIP", "мы", "нас" или
        "наш") собирают, используют и раскрывают информацию о вас. Настоящая
        Политика конфиденциальности распространяется на информацию, которую мы
        собираем, когда вы пользуетесь услугами DIGITIP ("Приложение" или "Услуги")
        или когда вы иным образом взаимодействуете с нами.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Внесение изменений
    </div>
    <div>
        Мы можем время от времени вносить изменения в настоящую Политику
        конфиденциальности. В случае внесения изменений мы уведомим вас об этом,
        изменив дату в верхней части Политики, а в некоторых случаях мы можем
        направить вам дополнительное уведомление (например, показать уведомление при
        авторизации в Приложении или отправить вам уведомление по электронной
        почте). Мы рекомендуем вам просматривать Политику конфиденциальности при
        каждом обращении к Услугам, чтобы быть в курсе нашей информационной практики
        и способов, которыми вы можете защитить свою конфиденциальность.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Персональные данные
    </div>
    <div>
        As used in this Policy, the term "personal information" means information
        that specifically identifies an individual (such as a name, user name, or
        e-mail address), or information about that individual that can be linked to
        information that specifically identifies an individual. Personal information
        does not include "aggregate" information, which is data we collect about the
        use of the Services or about a group or category of services or users, from
        which individual identities or other personal information has been removed.
        This Policy does not restrict our collection and use of aggregate
        information.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Сбор информации
    </div>
    <div>
        Информация, которую вы нам предоставляете. Мы собираем информацию, которую
        вы предоставляете нам напрямую. Например, мы собираем информацию, когда вы
        создаете учетную запись на нашем сайте, участвуете в интерактивных функциях
        Услуг, участвуете в опросах или иным образом общаетесь с нами. В число
        собираемой нами информации входят ваши имя и фамилия, адрес электронной
        почты и любая другая информация, которую вы решите предоставить.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Информация, которую мы собираем автоматически при использовании вами Услуг.
        Когда вы получаете доступ к нашим Услугам или пользуетесь ими, в том числе
        через веб-приложение, мы автоматически собираем информацию о вас, включая:
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Информация о ведении журнала:  Мы регистрируем информацию об использовании
        вами Услуг, включая тип используемой вами операционной системы; время
        доступа; вашу активность у участвующих Партнеров; геолокацию, если вы дали
        нам разрешение, для целей определения местонахождения участвующих Партнеров
        вблизи вас; способ доступа к Услугам, будь то через портал Приложения или
        через связанные с вами аккаунты в социальных сетях.
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Информация об устройстве:  Мы собираем информацию о мобильном
        устройстве, которое вы используете для доступа к нашим Услугам, включая
        модель оборудования, операционную систему и версию, уникальные
        идентификаторы устройства и информацию о мобильной сети.
    </div>
    <div>
        <br/>
    </div>
    <div>
        ·      Информация, собираемая с помощью файлов cookie и других технологий
        отслеживания: Для сбора информации мы используем различные технологии,
        которые могут включать отправку файлов cookie на ваше мобильное устройство.
        Файлы cookie - это небольшие файлы данных, хранящиеся в памяти устройства,
        которые помогают нам улучшать наши Услуги и ваш опыт, определять, какие
        области и функции наших Услуг пользуются популярностью, и подсчитывать
        количество посещений. Если вы хотите заблокировать, удалить или получить
        предупреждение о cookies и других методах отслеживания, обратитесь к
        настройкам конфиденциальности вашего мобильного устройства, чтобы узнать об
        этих функциях. Однако если вы решите удалить или отклонить файлы cookie и
        методы отслеживания, это может повлиять на некоторые функции или услуги,
        предоставляемые через Услуги. Независимо от того, что вы решили сделать, мы
        уважаем ваш выбор в пользу конфиденциальности.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Использование cookies и других методов отслеживания нашими партнерами,
        например, социальными сетями, которые вы используете для доступа к нашим
        Услугам, регулируется не нашим заявлением о конфиденциальности, а заявлением
        о конфиденциальности этих служб. Мы не имеем доступа и не контролируем
        методы обеспечения конфиденциальности, применяемые третьими сторонами.
        Пожалуйста, обязательно ознакомьтесь с политикой конфиденциальности этих
        третьих лиц, чтобы узнать, как они обращаются с вашей личной информацией.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Использование информации
    </div>
    <div>
        Мы не продаем вашу информацию. Мы можем использовать информацию о вас в
        различных целях, в том числе для:
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Предоставления, обслуживания и улучшения наших Услуг;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Отправки вам технических уведомлений, обновлений, предупреждений о
        безопасности, а также сообщений поддержки и административных сообщений;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Ответа на ваши комментарии, вопросы и запросы;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Сообщения вам об услугах, предложениях, акциях, вознаграждениях и
        мероприятиях, предлагаемых нами и другими компаниями, а также предоставления
        новостей и информации, которая, по нашему мнению, будет вам интересна;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - отслеживания и анализа тенденций, использования и деятельности в связи с
        нашими Услугами;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Персонализации и улучшения Услуг, а также предоставления рекламы, контента
        или функций, соответствующих профилю или интересам пользователя;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Связывания или объединения с информацией, которую мы получаем от других
        лиц, чтобы помочь понять ваши потребности и предоставить вам более
        качественное обслуживание;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Осуществления любых других целей, для которых была собрана информация,
        после того как мы уведомили вас о такой цели.
    </div>
    <div>
        <br/>
    </div>
    <div>
    </div>
    <div>
        <br/>
    </div>
    <div>
        Компания DIGITIP расположена в Эстонии и в настоящее время осуществляет свою
        деятельность только на ее территории. Собираемая нами информация
        регулируется эстонским законодательством.
    </div>
    <div>
        <br/>
    </div>
    <div>
    </div>
    <div>
        <br/>
    </div>
    <div>
        Распространение информации
    </div>
    <div>
        Мы можем предоставлять информацию о вас следующим образом или как это
        описано в настоящей Политике конфиденциальности:
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Партнерам, консультантам и другим поставщикам услуг, которым необходим
        доступ к такой информации для выполнения работы от нашего имени;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - С вашего согласия или по вашему указанию, в том числе если мы уведомляем
        вас через наши Услуги о том, что предоставленная вами информация будет
        передана определенным образом, и вы предоставляете такую информацию;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - В ответ на запрос информации, если мы считаем, что раскрытие информации
        соответствует любому применимому закону, нормативному акту или судебному
        процессу, или если иное требуется в соответствии с любым применимым законом,
        правилом или нормативным актом;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - Если вы нарушаете наши Условия или правила пользования, для защиты прав,
        собственности и безопасности компании DIGITIP, ее пользователей или других
        лиц;
    </div>
    <div>
        <br/>
    </div>
    <div>
        - В связи с любым слиянием, продажей активов компании, финансированием или
        приобретением всего или части нашего бизнеса другой компанией, а также в
        ходе переговоров об этом; а также
    </div>
    <div>
        <br/>
    </div>
    <div>
        Мы также можем передавать агрегированную или деидентифицированную
        информацию, которая не может быть разумно использована для установления
        вашей личности.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Обращаем ваше внимание на то, что мы не контролируем действия третьих лиц,
        на услуги которых вы можете быть перенаправлены через наш сайт, и не несем
        ответственности за информацию, которую вы предоставляете непосредственно
        другим пользователям или третьим лицам. Мы рекомендуем вам ознакомиться с
        правилами конфиденциальности третьих лиц, прежде чем напрямую передавать
        информацию таким третьим лицам.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Функции обмена в соцсетях
    </div>
    <div>
        В Услугах могут присутствовать функции обмена в соцсетях и другие
        интегрированные инструменты (например, кнопка Facebook "Like"), которые
        позволяют делиться действиями, выполняемыми в наших Услугах, с другими
        средствами массовой информации и наоборот. Использование таких функций
        позволяет делиться информацией с друзьями или широкой аудиторией, в
        зависимости от настроек, которые вы устанавливаете в организации,
        предоставляющей функцию социального обмена. Более подробную информацию о
        целях и объеме сбора и обработки данных в связи с использованием функций
        социального обмена можно получить в политике конфиденциальности организаций,
        предоставляющих эти функции.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Безопасность.  DIGITIP заботится о конфиденциальности и безопасности вашей
        информации. Мы принимаем меры для защиты вашей информации от потери,
        неправильного использования и несанкционированного доступа.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Ваши решения
    </div>
    <div>
        <br/>
    </div>
    <div>
        Информация об учетной записи. Вы можете обновить, исправить или удалить
        информацию о себе в любое время, войдя в свою учетную запись или отправив
        нам запрос по электронной почте info@DIGITIP.ee. Если вы хотите
        деактивировать свою учетную запись, вы можете сделать это в любое время,
        воспользовавшись инструментами вашей учетной записи или связавшись с нами по
        указанному выше адресу электронной почты, но учтите, что мы можем сохранять
        определенную информацию, если это требуется или разрешено законом.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Файлы cookie. На большинстве мобильных устройств в настройках
        конфиденциальности предусмотрена возможность отключения отслеживания третьих
        лиц. Обычно в настройках конфиденциальности по умолчанию установлено
        разрешение на использование файлов cookie и других технологий отслеживания.
        При желании можно настроить параметры конфиденциальности на удаление или
        отказ от отслеживания. Обратите внимание, что если вы решите удалить или
        отклонить файлы cookie и другие технологии отслеживания, это может повлиять
        на доступность и функциональность наших Услуг.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Сообщения рекламного характера. Вы можете отказаться от получения рекламных
        писем от нас, следуя инструкциям по отказу от подписки, содержащимся в этих
        письмах. Если вы откажетесь, мы все равно можем отправлять вам сообщения
        нерекламного характера, например, касающиеся вашего счета или наших текущих
        отношений по обслуживанию.
    </div>
    <div>
        <br/>
    </div>
    <div>
        Свяжитесь с нами
    </div>
    <div>
        Если у Вас возникли вопросы по настоящей Политике конфиденциальности,
        пожалуйста, свяжитесь с нами по адресу info@DIGITIP.ee.
    </div>`,
    },
};

/* eslint-enable max-len */
