import { Button, Dropdown, MenuProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { store } from '../../../../store/store';
import { setLocale } from '../../../../store/slices/commonSlice';
import { UiImage } from '../../../../ui/image/UiImage';
import { Locale } from '../../../navigator/types';

interface Props {
    onClick?: () => void;
}

export function LanguagesMenu({ onClick }: Props) {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const changeLanguage = (lng: Locale) => {
        store.dispatch(setLocale(lng));
        i18n.changeLanguage(lng);
        const newPathname = location.pathname.replace(/^(\/en|\/ee|\/ru)/, `/${lng}`);
        navigate(newPathname);
        onClick && onClick();
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Button type="link" onClick={() => changeLanguage('ee')}>
                    Eesti
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button type="link" onClick={() => changeLanguage('ru')}>
                    Русский
                </Button>
            ),
        },
        {
            key: '3',
            label: (
                <Button type="link" onClick={() => changeLanguage('en')}>
                    English
                </Button>
            ),
        },
    ];
    return (
        <Dropdown menu={{ items, style: { textAlign: 'center' } }} trigger={['click']}>
            <Button type="link">
                <UiImage src="assets/globe" />
                {i18n.language.toUpperCase()}
            </Button>
        </Dropdown>
    );
}
