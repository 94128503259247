import { styled } from 'styled-components';

export default styled.div`
    padding: 0 10px;
    .menu {
        margin-left: 20px;
    }

    .active {
        color: var(--color-primary);
    }
`;
