import { Card, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { UiButton } from '../../../../ui/button/UiButton';
import { useTranslation } from '../../../../hooks/use-translation';
import { removeToken } from '../../../../utils/token';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../../store/store';
import { setIsAuthenticated } from '../../../../store/slices/authSlice';
import axios from '../../../../utils/axios';
import { reset } from '../../../../store/slices/profileSlice';

export function DeleteAccount() {
    const { translate } = useTranslation();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    async function deleteAccount() {
        try {
            await axios.delete('/account/');
            removeToken();
            navigate('/');
            store.dispatch(setIsAuthenticated(false));
            store.dispatch(reset());
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Card title={translate('Delete account', 'profile.edit')} className="delete-account">
                <Space direction="vertical" size={15}>
                    {translate('After delete account you wont be able to restore it', 'profile.edit')}
                    <UiButton type="danger" onClick={() => setIsModalOpen(true)}>
                        Delete your account
                    </UiButton>
                </Space>
            </Card>
            <Modal open={isModalOpen} getContainer={false} onCancel={() => setIsModalOpen(false)} footer={false}>
                {translate('Are you sure to remove account?', 'profile.edit')}
                <Space direction="vertical">
                    <UiButton type="danger" onClick={() => deleteAccount()}>
                        {translate('Delete account', 'profile.edit')}
                    </UiButton>
                    <UiButton onClick={() => setIsModalOpen(false)}>{translate('Cancel', 'profile.edit')}</UiButton>
                </Space>
            </Modal>
        </>
    );
}
