import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { Alert, Card, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { register } from '../../../api/auth';
import { RootState, store } from '../../../store/store';
import { setIsAuthenticated } from '../../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { getHeightToFitInWindow, getRoute } from '../../../utils/browser';
import { setIsFooterHidden } from '../../../store/slices/footerSlice';
import Container from '../../container/Container';
import { UiImage } from '../../../ui/image/UiImage';
import { useTranslation } from '../../../hooks/use-translation';
import { UiButton } from '../../../ui/button/UiButton';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { LoginHint } from '../hint/Hint';
import { AxiosError } from 'axios';
import { setToken } from '../../../utils/token';
import i18n from '../../../utils/i18n';

export function Registration() {
    const [pageHeight, setPageHeight] = useState(0);
    const [isLoginInProgress, setIsLoginInProgress] = useState(false);
    const navigate = useNavigate();
    const { translate } = useTranslation();
    const email = useSelector((state: RootState) => state.auth.preFilledEmail);
    const [form] = Form.useForm();
    const [formErrors, setFormErrors] = useState<string[]>([]);

    useEffect(() => {
        store.dispatch(setIsFooterHidden(true));
        const heightToFitWindow = getHeightToFitInWindow('.login-page');
        setPageHeight(heightToFitWindow);

        return () => {
            store.dispatch(setIsFooterHidden(false));
        };
    }, []);

    async function signup() {
        setIsLoginInProgress(true);
        setFormErrors([]);
        const { email, password, firstName, lastName } = form.getFieldsValue();
        try {
            const response = await register(email, password, firstName, lastName, i18n.language);
            setToken(response.data);
            store.dispatch(setIsAuthenticated(true));
            navigate(getRoute('profile'));
        } catch (err) {
            if (err instanceof AxiosError) {
                const issues = err.response?.data.issues.map((issue: any) => issue.message);
                setFormErrors(issues);
            }
        } finally {
            setIsLoginInProgress(false);
        }
    }

    return (
        <Container>
            <Wrapper height={pageHeight} className="registration">
                <Card>
                    <ArrowLeftOutlined onClick={() => navigate(getRoute('login'))} className="back-btn" />
                    <UiImage src="assets/registration_hero" />
                    <Divider>{translate('Email registration', 'registration')}</Divider>
                    <Form onFinish={signup} form={form} autoComplete="off" initialValues={{ email }}>
                        <Form.Item rules={[{ required: true, message: '' }]} name="firstName">
                            <Input size="large" placeholder={translate('First name', 'registration')} autoFocus />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: '' }]} name="lastName">
                            <Input size="large" placeholder={translate('Last name', 'registration')} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: '' }]} name="email">
                            <Input size="large" placeholder={translate('Email', 'registration')} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true, message: '' }]} name="password">
                            <Input type="password" size="large" placeholder={translate('Password', 'registration')} />
                        </Form.Item>
                        <Form.Item>
                            <UiButton size="large" type="secondary" htmlType="submit">
                                {translate('Register', 'reistration')}
                            </UiButton>
                        </Form.Item>
                    </Form>
                    <LoginHint action="register" />
                    {isLoginInProgress && (
                        <Row justify="center">
                            <Col>
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}></Spin>
                            </Col>
                        </Row>
                    )}
                    {Boolean(formErrors.length) && (
                        <Alert
                            type="error"
                            description={
                                <ul>
                                    {formErrors.map((err) => {
                                        return <li key={err}>{err}</li>;
                                    })}
                                </ul>
                            }
                        />
                    )}
                </Card>
            </Wrapper>
        </Container>
    );
}
