import React from 'react';
import Wrapper from './styles';
import { useTranslation } from '../../../hooks/use-translation';
import { getRoute } from '../../../utils/browser';

type Props = {
    action?: string;
};

export function LoginHint({ action = 'login' }: Props) {
    const { translateHtml } = useTranslation();
    return (
        <Wrapper>
            {translateHtml(
                `By clicking ${action}, you agree with <a href="${getRoute('privacy')}" target="_blank">privacy policy</a>`,
                'registration',
            )}
        </Wrapper>
    );
}
