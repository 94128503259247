import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { LanguagesMenu } from '../languages/Languages';
import { Avatar, Space } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import Wrapper from './styles';
import { getRoute } from '../../../../utils/browser';
import { UiImage } from '../../../../ui/image/UiImage';
import classNames from 'classnames';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from '../../../../hooks/use-translation';
import { getRedirectLink } from '../helper';

export function MenuDesktop() {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const { translate } = useTranslation();
    const resolved = useResolvedPath(window.location);
    const match = useMatch({ path: resolved.pathname, end: true });

    const loggedOutMenuItems = [
        {
            label: translate('How it works', 'header'),
            url: getRoute('#how-it-works'),
        },
        {
            label: translate('For whom', 'header'),
            url: getRoute('#who-needs-it'),
        },
        {
            label: translate('Tips', 'header'),
            url: getRoute('#tips'),
        },
        {
            label: translate('Prices', 'header'),
            url: getRoute('#prices'),
        },
    ];

    const loggedInMenuItems = [
        {
            label: translate('Profile', 'header'),
            url: getRoute('profile'),
        },
        {
            label: translate('Templates', 'header'),
            url: getRoute('templates'),
        },
    ];

    return (
        <Wrapper className="header">
            <div className="logo">
                <Link to={getRedirectLink(isAuthenticated)}>
                    <UiImage src="assets/logo" />
                </Link>
            </div>
            {!isAuthenticated && (
                <>
                    <div className="menu">
                        <Space size={20}>
                            {loggedOutMenuItems.map((item) => {
                                return (
                                    <Link
                                        key={item.url}
                                        to={item.url}
                                        className={classNames({ active: match?.pathname + window.location.hash === item.url })}
                                    >
                                        {item.label}
                                    </Link>
                                );
                            })}

                            <Link to={getRoute('login')} className="primary">
                                <Space size={10}>
                                    <UiImage src="assets/burn" />
                                    {translate('Join', 'header')}
                                </Space>
                            </Link>
                        </Space>
                    </div>
                    <div className="actions">
                        <div className="lang">
                            <LanguagesMenu />
                        </div>
                        <Space className="signup-login">
                            <Link to={getRoute('login')} className="login">
                                {translate('Sign Up', 'header')} / {translate('Login', 'header')}
                            </Link>
                        </Space>
                    </div>
                </>
            )}

            {isAuthenticated && (
                <>
                    <div className="menu">
                        <Space size={20}>
                            {loggedInMenuItems.map((item) => {
                                return (
                                    <Link key={item.url} to={item.url} className={classNames({ active: match?.pathname === item.url })}>
                                        {item.label}
                                    </Link>
                                );
                            })}

                            <Link to={getRoute('account/withdraw')} className="primary">
                                {translate('Withdraw', 'header')}
                            </Link>
                        </Space>
                    </div>
                    <div className="actions">
                        <div className="lang">
                            <LanguagesMenu />
                        </div>
                        <Space className="account">
                            <Link to={getRoute('profile')}>
                                <Space size={15}>
                                    {Boolean(userProfile?.profilePicture) ? (
                                        <Avatar size={56} src={userProfile?.profilePicture} />
                                    ) : (
                                        <Avatar style={{ backgroundColor: '#87d068' }} size={56} icon={<UserOutlined />} />
                                    )}
                                </Space>
                            </Link>
                        </Space>
                    </div>
                </>
            )}
        </Wrapper>
    );
}
