import styled from 'styled-components';
import { size } from '../../../utils/variables';

export default styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
    max-width: ${size.xl};
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 75px;

    .ant-card {
        position: relative;
        max-width: 570px;
        width: 100%;
        padding: 2.75rem 2.125rem;
        box-shadow: 0px 9px 54px 0px rgba(215, 218, 222, 0.2);

        h2 {
            margin: 2rem 0;
            text-align: center;
        }

        img {
            display: block;
            margin: 0 auto;
        }

        button {
            width: 100%;
        }
    }
`;
