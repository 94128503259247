import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { authWithSocial } from '../../../api/auth';
import { setIsAuthenticated } from '../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { UiImage } from '../../../ui/image/UiImage';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../../utils/browser';
import { useTranslation } from '../../../hooks/use-translation';
import { setToken } from '../../../utils/token';
import i18n from '../../../utils/i18n';

interface Props {
    setLoginIsInProgress: (value: boolean) => void;
}

export function LoginGoogleButton({ setLoginIsInProgress }: Props) {
    const [accessToken, setAccessToken] = useState('');
    const dispatch = useDispatch();
    const { translate } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        authenticateWithToken();
    }, [accessToken]);

    async function authenticateWithToken() {
        if (!accessToken) return;
        setLoginIsInProgress(true);
        try {
            const response = await authWithSocial({ accessToken }, 'GOOGLE');
            const { locale, ...tokens } = response.data;
            setToken(tokens);
            dispatch(setIsAuthenticated(true));
            i18n.changeLanguage(locale);
            navigate(getRoute('profile'));
        } catch (err: any) {
            console.log(err);
        }
        setLoginIsInProgress(false);
    }

    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            setAccessToken(tokenResponse.access_token);
        },
    });
    return (
        <div
            onClick={() => {
                login();
            }}
        >
            <UiImage src="assets/login-google" />
            <span>{translate('Continue with', 'auth')} Google</span>
        </div>
    );
}
