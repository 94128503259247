import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Divider, Input, InputRef, Row, Spin, Typography } from 'antd';
import { LoginFacebook } from '../facebook/LoginFacebook';
import { LoginGoogle } from '../google/LoginGoogle';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from '../../../hooks/use-translation';
import { getIsEmailAvailable } from '../../../api/auth';
import { useNavigate } from 'react-router-dom';
import { getHeightToFitInWindow, getRoute } from '../../../utils/browser';
import Wrapper from './styles';
import { RootState, store } from '../../../store/store';
import { setIsFooterHidden } from '../../../store/slices/footerSlice';
import { useSelector } from 'react-redux';
import { setPreFilledEmail } from '../../../store/slices/authSlice';
import { LoginHint } from '../hint/Hint';
import { UiButton } from '../../../ui/button/UiButton';

export function LoginForm() {
    const [pageHeight, setPageHeight] = useState(0);
    const [isLoginInProgress, setIsLoginInProgress] = useState(false);
    const { translate } = useTranslation();
    const preFilledEmail = useSelector((state: RootState) => state.auth.preFilledEmail);
    const emailRef = useRef<InputRef>(null);
    const navigate = useNavigate();

    useEffect(() => {
        store.dispatch(setIsFooterHidden(true));
        const heightToFitWindow = getHeightToFitInWindow('.login-page');
        setPageHeight(heightToFitWindow);

        return () => {
            store.dispatch(setIsFooterHidden(false));
        };
    }, []);

    async function checkEmail() {
        const email = emailRef.current?.input?.value || '';
        const isEmailAvailable = await getIsEmailAvailable(email);
        store.dispatch(setPreFilledEmail(email));
        if (isEmailAvailable) {
            navigate(getRoute('login/register'));
        } else {
            navigate(getRoute('login/email'));
        }
    }

    function onPressEnter(key: string) {
        if (key === 'Enter') {
            checkEmail();
        }
    }

    return (
        <Wrapper height={pageHeight} className="login-page">
            <Card>
                <Typography.Title level={2}>{translate('Log in and start receiving tips', 'auth')}</Typography.Title>
                <div className="social-links">
                    <LoginFacebook setLoginIsInProgress={setIsLoginInProgress} />
                    <LoginGoogle setLoginIsInProgress={setIsLoginInProgress} />
                </div>
                <div className="register-email-label">
                    <Divider plain>{translate('Register with E-mail', 'auth')}</Divider>
                </div>
                <Input
                    placeholder="E-mail"
                    defaultValue={preFilledEmail}
                    ref={emailRef}
                    onKeyDown={(element) => onPressEnter(element.key)}
                    autoFocus
                />

                <UiButton type="secondary" className="login-btn" size="large" onClick={checkEmail}>
                    {translate('Login', 'auth')}
                </UiButton>
                <LoginHint />
                {isLoginInProgress && (
                    <Row justify="center">
                        <Col>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}></Spin>
                        </Col>
                    </Row>
                )}
            </Card>
        </Wrapper>
    );
}
