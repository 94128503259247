import axios from '../../utils/axios';
import { Balances } from './types';

export const getBalances = async () => {
    try {
        const resp = await axios.get<Balances>('/wallet/balances');
        return resp.data;
    } catch (err) {
        console.log(err);
    }
};
