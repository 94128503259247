import { styled } from 'styled-components';

export default styled.div`
    display: flex;
    justify-content: center;
    opacity: 0.6;
    color: var(--color-dark-purple);
    margin-top: 0.5rem;

    a {
        text-decoration: underline;
    }
`;
