import React from 'react';
import { UiImage } from '../../../../ui/image/UiImage';
import { Typography } from 'antd';
import { useTranslation } from '../../../../hooks/use-translation';
import Wrapper from './styles';
import { getRoute } from '../../../../utils/browser';
import { UiButton } from '../../../../ui/button/UiButton';
import { useNavigate } from 'react-router-dom';

export function ResetPasswordSuccess() {
    const { translate } = useTranslation();
    const navigate = useNavigate();
    return (
        <Wrapper>
            <UiImage src="assets/reset-password" className="success" />
            <Typography.Title level={2}>{translate('Password changed', 'reset-password')}</Typography.Title>
            <Typography.Text>{translate('Your password has been changed successfully', 'reset-password')}</Typography.Text>
            <UiButton type="secondary" size="large" onClick={() => navigate(getRoute('login'))}>
                Back to login
            </UiButton>
        </Wrapper>
    );
}
