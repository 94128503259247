import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isTokenValid } from '../../utils/token';
import { revertAll } from '../actions/common';

export interface AuthSlice {
    isAuthenticated: boolean;
    preFilledEmail?: string;
}

const initialState: AuthSlice = {
    isAuthenticated: isTokenValid(),
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isAuthenticated: action.payload,
            };
        },
        setPreFilledEmail: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                preFilledEmail: action.payload,
            };
        },
    },
});

export const { setIsAuthenticated, setPreFilledEmail } = authSlice.actions;

export const authReducer = authSlice.reducer;
