import { styled } from 'styled-components';
import { device } from '../../../utils/variables';

export default styled.div<{ profileimage?: string }>`
    .ant-space {
        width: 100%;
    }
    a {
        margin-bottom: 20px;
        display: flex;

        .anticon {
            margin-right: 15px;
        }
    }

    .profile-image {
        width: 240px;
        height: 340px;
        background-color: var(--bg-gray);
        border-radius: 8px;
        margin: 0 auto;

        background-image: url('${(props) => props.profileimage}');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        display: flex;
        justify-content: center;
        align-items: center;

        .default-profile {
            font-size: 60px;
        }
    }

    .image-actions {
        display: flex;
        gap: 10px;
        margin-top: 10px;
    }

    button {
        flex: 1;
        height: auto;
        padding: 12px 0;
        width: 100%;

        &.change-password {
            margin-top: 10px;
        }
    }

    .back-btn {
        text-align: left;
    }

    .ant-modal-body {
        padding: 3rem 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 20px;

        img {
            width: 100%;
        }
    }

    .delete-account {
        .ant-card-head-title {
            color: var(--color-primary);
        }
    }

    .ant-upload-wrapper {
        flex: 1;

        .ant-upload {
            width: 100%;
        }
    }

    .profile-card {
        display: flex;
        flex-direction: column;

        @media ${device.lg} {
            flex-direction: row;
            gap: 30px;
        }

        .profile-wrapper {
            min-width: 340px;
        }
    }
`;
