import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { apiBaseUrl } from './axios';
import { eePrivacy, enPrivacy, ruPrivacy } from '../i18n/privacy';
import { Locale } from '../components/navigator/types';

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        fallbackLng: false,
        keySeparator: false,
        nsSeparator: false,
        // debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
        },
        detection: {
            lookupQuerystring: 'lang',
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        },
        backend: {
            loadPath: `${apiBaseUrl}/translations/locales/{{lng}}/{{ns}}`,
            addPath: `${apiBaseUrl}/translations/locales/add/{{lng}}/{{ns}}`,
        },
    });

const privacyByLang: Record<Locale, object> = {
    en: enPrivacy,
    ee: eePrivacy,
    ru: ruPrivacy,
};

Object.keys(privacyByLang).forEach((lang) => {
    i18n.addResourceBundle(lang, 'privacy', privacyByLang[lang]);
});

export default i18n;
