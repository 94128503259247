import React, { useEffect } from 'react';
import './App.css';
import { ConfigProvider } from 'antd';
import { Navigator } from './components/navigator/Navigator';
import { getRoutes } from './routes';
import { LayoutBasic } from './pages/layout/LayoutBasic';
import { useSelector } from 'react-redux';
import { RootState, store } from './store/store';
import { getAccountProfile } from './api/account';
import { setProfile } from './store/slices/profileSlice';
import { App as AntdApp } from 'antd';

export function App() {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    // TODO: refactor this shit, need to find other way around
    useEffect(() => {
        if (isAuthenticated) {
            getAccountInfo();
        }
    }, [isAuthenticated]);

    async function getAccountInfo() {
        const accountInfo = await getAccountProfile();
        if (accountInfo) {
            store.dispatch(setProfile(accountInfo));
        }
    }

    const routes = getRoutes(isAuthenticated);
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#FF443B',
                    fontFamily: 'Golos',
                    colorText: '#2E124C',
                    colorLinkActive: '#2E124C',
                    colorLink: '#2E124C',
                    colorLinkHover: '#2E124C',
                },
                components: {
                    Input: {
                        paddingBlockLG: 15,
                    },
                    InputNumber: {
                        paddingBlockLG: 15,
                    },
                    Select: {
                        controlHeightLG: 52,
                    },
                },
            }}
        >
            <AntdApp style={{ width: '100%' }}>
                <LayoutBasic>
                    <Navigator routes={routes} />
                </LayoutBasic>
            </AntdApp>
        </ConfigProvider>
    );
}
