import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginGoogleButton } from './LoginGoogleButton';

interface Props {
    setLoginIsInProgress: (value: boolean) => void;
}

export function LoginGoogle(props: Props) {
    return (
        <GoogleOAuthProvider clientId="981813921791-0e3rrsg2045v435m7ntt6tfl4pft82gf.apps.googleusercontent.com">
            <LoginGoogleButton {...props} />
        </GoogleOAuthProvider>
    );
}
