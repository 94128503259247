import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Locale } from '../../components/navigator/types';
import { revertAll } from '../actions/common';

export interface CommonSlice {
    locale: Locale;
    defaultLocale: Locale;
}

const initialState: CommonSlice = {
    locale: 'ee',
    defaultLocale: 'ee',
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setLocale: (state, action: PayloadAction<Locale>) => {
            state.locale = action.payload;
        },
    },
});

export const { setLocale } = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
