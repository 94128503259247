import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { revertAll } from '../actions/common';

export interface FooterState {
    isFooterHidden: boolean;
}

const initialState: FooterState = {
    isFooterHidden: false,
};

export const footerSlice = createSlice({
    name: 'footer',
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
    reducers: {
        setIsFooterHidden: (state, action: PayloadAction<boolean>) => {
            state.isFooterHidden = action.payload;
        },
    },
});

export const { setIsFooterHidden } = footerSlice.actions;

export const footerReducer = footerSlice.reducer;
