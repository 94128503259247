import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { Card } from 'antd';
import { store } from '../../../store/store';
import { setIsFooterHidden } from '../../../store/slices/footerSlice';
import { getHeightToFitInWindow } from '../../../utils/browser';
import { Outlet } from 'react-router-dom';

export function AccountResetPasswordPage() {
    const [pageHeight, setPageHeight] = useState(0);

    useEffect(() => {
        store.dispatch(setIsFooterHidden(true));
        const heightToFitWindow = getHeightToFitInWindow('.container');
        setPageHeight(heightToFitWindow);

        return () => {
            store.dispatch(setIsFooterHidden(false));
        };
    }, []);

    return (
        <Wrapper height={pageHeight} className="container">
            <Card>
                <Outlet />
            </Card>
        </Wrapper>
    );
}
