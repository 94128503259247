import { configureStore } from '@reduxjs/toolkit';
import { footerReducer } from './slices/footerSlice';
import { authReducer } from './slices/authSlice';
import { profileReducer } from './slices/profileSlice';
import { commonReducer } from './slices/commonSlice';

export const store = configureStore({
    reducer: {
        footer: footerReducer,
        auth: authReducer,
        profile: profileReducer,
        common: commonReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
