import i18n from './i18n';

export function getHeightToFitInWindow(selector: string, bottomOffset = 0, height?: number) {
    const element = document.querySelector<HTMLElement>(selector)?.getBoundingClientRect();
    const windowHeight = height || window.innerHeight;

    if (element?.top) {
        return windowHeight - element.top - bottomOffset;
    }

    return 0;
}

export const getHeightToFitInWindowWithElementOffset = (selector: string, offsetSelector: string) => {
    const footer = document.querySelector<HTMLElement>(offsetSelector)?.getBoundingClientRect();
    return getHeightToFitInWindow(selector, footer?.height ?? 0);
};

export const getRoute = (route: string) => {
    const locale = i18n.language;
    return `/${locale}/${route}`;
};
