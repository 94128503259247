import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../store/store';

export const PrivateRoutes = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const checkIsLoggedIn = () => {
        return isAuthenticated;
    };

    return checkIsLoggedIn() ? <Outlet /> : <Navigate to="/" />;
};
