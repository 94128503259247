import React from 'react';
import Wrapper from './styles';
import { Grid } from 'antd';
import { MenuMobile } from './menu/mobile/MenuMobile';
import { MenuDesktop } from './menu/desktop/MenuDesktop';

const { useBreakpoint } = Grid;

export function Header() {
    const sizes = useBreakpoint();

    return (
        <Wrapper>
            {(sizes.xs || sizes.md) && !sizes.lg && <MenuMobile />}
            {sizes.lg && <MenuDesktop />}
        </Wrapper>
    );
}
