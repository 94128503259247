import { styled } from 'styled-components';

export default styled.div`
    width: 100%;

    .spinner-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10em 0;
    }
`;
