import { lazy } from 'react';
import { ProfileEdit } from './components/profile/edit/ProfileEdit';
import { LoginEmailAndPassword } from './components/login/email-and-password/LoginEmailAndPassword';
import { LoginForm } from './components/login/form/LoginForm';
import { Registration } from './components/login/registration/Registration';
import { AccountResetPasswordPage } from './pages/account/reset-password/ResetPasswordPage';
import { ResetPasswordForm } from './components/account/reset-password/form/ResetPasswordForm';
import { ResetPasswordSuccess } from './components/account/reset-password/success/ResetPasswordSuccess';
import { ChangePasswordPage } from './pages/profile/change-password/ChangePasswordPage';
import { WithdrawPage } from './pages/account/withdraw/WithdrawPage';
import { PageRouteProps } from './components/navigator/types';
import { VerifyAccount } from './components/verify-account/VerifyAccount';

const HomePage = lazy(() => import('./pages/home/HomePage').then((module) => ({ default: module.HomePage })));
const LoginPage = lazy(() => import('./pages/login/LoginPage').then((module) => ({ default: module.LoginPage })));
const TipPage = lazy(() => import('./pages/tip/TipPage').then((module) => ({ default: module.TipPage })));
const TipSuccessPage = lazy(() => import('./pages/tip/success/TipSuccessPage').then((module) => ({ default: module.TipSuccessPage })));
const PrivacyPage = lazy(() => import('./pages/privacy/PrivacyPage').then((module) => ({ default: module.PrivacyPage })));
const TermsPage = lazy(() => import('./pages/terms/TermsPage').then((module) => ({ default: module.TermsPage })));
const FaqPage = lazy(() => import('./pages/faq/FaqPage').then((module) => ({ default: module.FaqPage })));
const ProfilePage = lazy(() => import('./pages/profile/ProfilePage').then((module) => ({ default: module.ProfilePage })));
const TemplatesPage = lazy(() => import('./pages/templates/TemplatesPage').then((module) => ({ default: module.TemplatesPage })));
const SettingsPage = lazy(() => import('./pages/settings/SettingsPage').then((module) => ({ default: module.SettingsPage })));
const ResetPasswordPage = lazy(() =>
    import('./pages/reset-password/ResetPasswordPage').then((module) => ({ default: module.ResetPasswordPage })),
);
const ContactsPage = lazy(() => import('./pages/contacts/ContactsPage').then((module) => ({ default: module.Contacts })));
const VerifyAccountPage = lazy(() =>
    import('./pages/verify-account/VerifyAccountPage').then((module) => ({ default: module.VerifyAccountPage })),
);

export function getRoutes(isAuthenticated: boolean): PageRouteProps[] {
    const routes: PageRouteProps[] = [
        {
            path: 'verify',
            Component: VerifyAccountPage,
            loginRequired: true,
            routes: [{ path: '', Component: VerifyAccount, index: true }],
        },
        {
            path: 'settings',
            Component: SettingsPage,
            loginRequired: true,
            routes: [
                {
                    path: 'profile',
                    Component: ProfileEdit,
                    index: true,
                },
                {
                    path: 'change-password',
                    Component: ChangePasswordPage,
                },
            ],
        },
        {
            path: 'templates',
            Component: TemplatesPage,
            loginRequired: true,
        },
        {
            path: 'profile',
            Component: ProfilePage,
            loginRequired: true,
        },
        {
            path: 'faq',
            Component: FaqPage,
        },
        {
            path: 'privacy',
            Component: PrivacyPage,
        },
        {
            path: 'terms',
            Component: TermsPage,
        },
        {
            path: 'tip',
            routes: [
                {
                    path: ':pin',
                    Component: TipPage,
                    index: true,
                },
                {
                    path: 'success',
                    Component: TipSuccessPage,
                },
            ],
        },
        {
            path: 'login',
            Component: LoginPage,
            loggedInProhibited: true,
            routes: [
                {
                    path: '',
                    index: true,
                    Component: LoginForm,
                },
                {
                    path: 'email',
                    Component: LoginEmailAndPassword,
                },
                {
                    path: 'register',
                    Component: Registration,
                },
            ],
        },
        {
            path: 'reset-password',
            Component: ResetPasswordPage,
        },
        {
            path: 'account',
            routes: [
                {
                    path: 'reset-password',
                    Component: AccountResetPasswordPage,
                    routes: [
                        {
                            path: '',
                            index: true,
                            Component: ResetPasswordForm,
                        },
                        {
                            path: 'success',
                            Component: ResetPasswordSuccess,
                        },
                    ],
                },
                {
                    path: 'withdraw',
                    Component: WithdrawPage,
                    loginRequired: true,
                },
            ],
        },
        {
            path: 'contacts',
            Component: ContactsPage,
        },
        {
            path: '',
            Component: HomePage,
            index: true,
        },
    ];
    return routes.filter((route) => (isAuthenticated ? !(route.loggedInProhibited ?? false) : true));
}
