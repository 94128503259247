const STORAGE_TOKEN_KEY = 'digitip_tokens';

export function getTokens(): { accessToken: string; refreshToken: string } {
    const tokensStr = localStorage.getItem(STORAGE_TOKEN_KEY);
    return JSON.parse(tokensStr ?? '{}');
}

export function setToken(tokens: AppToken) {
    localStorage.setItem(STORAGE_TOKEN_KEY, JSON.stringify(tokens));
}

export function isTokenValid() {
    const token = getTokens();
    return Boolean(Object.keys(token).length);
}

export function removeToken() {
    localStorage.removeItem(STORAGE_TOKEN_KEY);
}

interface AppToken {
    accessToken?: string;
    refreshToken?: string;
}
