import { ButtonProps } from 'antd';
import { ButtonType } from 'antd/es/button';
import React from 'react';
import BtnWrapper from './styles';

interface Props extends Omit<ButtonProps, 'type'> {
    type?: ButtonType | 'success' | 'info' | 'danger' | 'secondary';
    className?: string;
    children?: React.ReactNode;
}

export function UiButton({ type = 'default', className, children, ...rest }: Props) {
    const btnType = ['default', 'primary', 'dashed', 'link', 'text'].includes(type) ? type : 'default';
    return (
        <BtnWrapper type={btnType as ButtonType} className={`${type} ${className}`} {...rest}>
            {children}
        </BtnWrapper>
    );
}
