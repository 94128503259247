import { styled } from 'styled-components';
import { size } from '../../../utils/variables';

export default styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
    max-width: ${size.xl};
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 75px;

    .ant-card {
        max-width: 570px;
        width: 100%;
        padding: 44px 34px;
        box-shadow: 0px 9px 54px 0px rgba(215, 218, 222, 0.2);

        img {
            margin: 0 auto;
            display: block;
        }

        h2 {
            margin-top: 0;
            text-align: center;
        }

        button {
            width: 100%;
        }

        .back-btn {
            position: absolute;
            top: 2rem;
            left: 2.75rem;
            font-size: 1rem;
            cursor: pointer;
        }
    }

    .ant-form-item-has-error {
        input {
            border: 1px solid #ff443b;
            background: #fff5f5;

            &::placeholder {
                color: #ff443b;
            }
        }
    }

    .ant-alert {
        margin-top: 1rem;
        padding: 10px 15px;
    }
`;
