import { styled } from 'styled-components';

export default styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    width: 100%;

    img {
        max-width: 100%;
    }

    .anticon-menu {
        font-size: 25px;
    }
`;
